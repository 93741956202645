import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { customerDashboardActions } from "../../../../../../../redux/actions";
import {
  customToast,
  convertDateToUTC,
  ClearReelStorage,
} from "../../../../../../../shared/utility";
import dummypic from "../../../../../../../shared/img/dummyPic.jpg";
import {
  customerConstants,
  eventBookingConstants,
} from "../../../../../../../constants";
import { NavigateToPath } from "../../../../../../../system";

const ExtendYourSb = (props) => {
  const { ...myEventsExtendSb } = props?.myEventsExtendSb ?? {};
  const { t } = useTranslation("common");
  const { eventId } = useParams();
  const history = useHistory();

  const handleCheckout = (e) => {
    e.preventDefault();
    ClearReelStorage();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    const selectedSb = myEventsExtendSb?.orders[0]?.orderItems?.filter((sb) => {
      const inputName = sb?.sbUser?.firstName + sb?.sbUser?.id;
      if (formProps[inputName]) {
        sb.extendedPrice_ = sb?.sbUser?.hourlyRate;
        sb.extendedQuantity_ = 1;
        return true;
      } else {
        return false;
      }
    });

    // returning if no sb is selected
    if (selectedSb.length === 0) {
      customToast(t("please_select_shutterb"), toast.TYPE.ERROR);
      return;
    }
    props.addToCustomerDashboardCart(selectedSb);
    localStorage.setItem("activeTab", eventBookingConstants.TABS.CART);
    if (
      localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) ===
      customerConstants.TIP_TO_CART
    ) {
      localStorage.setItem(
        customerConstants.CART_PREVIOUS_SCREEN,
        customerConstants.EXTEND_AND_TIP_TO_CART
      );
    } else if (
      localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) !==
      customerConstants.EXTEND_AND_TIP_TO_CART
    ) {
      localStorage.setItem(
        customerConstants.CART_PREVIOUS_SCREEN,
        customerConstants.EXTEND_TO_CART
      );
    }
    localStorage.setItem(customerConstants.NOT_NEW_EVENT, JSON.stringify(true));
    NavigateToPath(history, "/event_booking");
    // dataobj and id

    // const dataObj = {
    //   ...myEventsExtendSb,
    //   duration: 1,
    //   orders: [
    //     {
    //       ...myEventsExtendSb.orders[0],
    //       orderItems: selectedSb.map((sb) => ({
    //         ...sb,
    //         extendedQuantity: 1,
    //         extendedPrice: sb.sbUser.hourlyRate,
    //         extendedStatus: "pending",
    //       })),
    //     },
    //   ],
    // };

    // props.extendSbCheckout(
    //   myEventsExtendSb?.id,
    //   dataObj,
    //   customerConstants.EXTEND_TO_CART,
    //   history
    // );
  };

  useEffect(() => {
    props.getMyEventsExtendSb(eventId, props.userId);
    // eslint-disable-next-line
  }, []);

  // disable the sb that is added in cart
  let sbInCart;
  // props.customerDashboardCart.length > 0 &&
  //   localStorage.getItem(customerConstants.CART_PREVIOUS_SCREEN) ===
  //     customerConstants.EXTEND_TO_CART
  if (props.customerDashboardCart.length > 0) {
    sbInCart = props.customerDashboardCart
      .filter((sb) => sb.extendedPrice_)
      .map((sb) => {
        return sb.sbuserId;
      });
  }

  return (
    <div className="container extend-sb">
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center flex-column">
          <p className="p-0 m-0">
            {myEventsExtendSb?.eventName +
              " - " +
              convertDateToUTC(myEventsExtendSb?.eventDate)}
          </p>
          <p className="p-0 m-0">
            <strong> Event ID:</strong> {myEventsExtendSb?.id}
          </p>
        </div>
      </div>
      <div className="row my-3">
        <div className="col px-0">
          <h6 className="p-0 m-0 mb-2">Extend your shutterb</h6>
        </div>
      </div>
      {/* list of all shutterbs of event */}
      <div className="row px-0">
        {myEventsExtendSb?.orders &&
        myEventsExtendSb?.orders[0]?.orderItems?.length ? (
          <form className="col px-0" onSubmit={handleCheckout}>
            {myEventsExtendSb?.orders[0]?.orderItems?.map((sb) => (
              <div
                className={
                  "py-4 " +
                  (((sbInCart && sbInCart.includes(sb.sbuserId)) ||
                    sb?.sbUser?.isExtended) &&
                    "disable-div")
                  // "py-4 disable-div"
                }
              >
                <div className="sb-list-item row d-flex justify-content-start mb-3">
                  <div className="profile-img-con">
                    <label
                      className="profile-img"
                      htmlFor={sb?.sbUser?.firstName + sb?.sbUser?.id}
                    >
                      <img
                        src={
                          sb?.sbUser?.sbPortfolios[0]?.profileImageUrl
                            ? process.env.REACT_APP_S3_BASE_URL_PORTFOLIO +
                              "/" +
                              sb?.sbUser?.sbPortfolios[0]?.profileImageUrl
                            : dummypic
                        }
                        alt=""
                      />
                    </label>
                    <input
                      type="checkbox"
                      name={sb?.sbUser?.firstName + sb?.sbUser?.id}
                      id={sb?.sbUser?.firstName + sb?.sbUser?.id}
                    />
                  </div>
                  <h6 className="px-2 px-md-4 pt-3">
                    {sb?.sbUser?.firstName + " " + sb?.sbUser?.lastName}
                  </h6>
                </div>
                <div className="row sb-price-items">
                  {/* <div className="col-md-4 pl-0">
                    <label htmlFor="duration">Duration (Hours)</label>
                    <input type="number" id="duration" readOnly value={1} />
                  </div>
                  <div className="col-md-4 pl-0">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      id="amount"
                      readOnly
                      value={sb?.sbUser?.hourlyRate}
                    />
                  </div> */}
                  <div className="col-md-12 pl-0">
                    <p>
                      Extend your shutterb(z) for an additional hour below for
                      an additional ${window.config.shutterBExtendPrice} per
                      shutterb.
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="checkout-btn col-md-3 pl-0">
              <button
                type="submit"
                className="btn-simple w-100 align-items-center d-flex justify-content-center"
              >
                Checkout
              </button>
            </div>
          </form>
        ) : (
          <p>The event request has not been accepted by any shutterb yet.</p>
        )}
      </div>
    </div>
  );
};

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userId: state.authentication.userId,
  myEventsExtendSb: state.customerDashboard.myEventsExtendSb,
  customerDashboardCart: state.customerDashboard.customerDashboardCart,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getMyEventsExtendSb: (eventId) =>
    dispatch(customerDashboardActions.myEventsExtendSb(eventId)),
  addToCustomerDashboardCart: (dataObj) =>
    dispatch({
      type: customerConstants.ADD_TO_CUSTOMER_DASHBOARD_CART,
      data: dataObj,
    }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExtendYourSb));

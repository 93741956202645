import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Modal from "react-modal";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { customerDashboardActions } from "../../../redux/actions";
import {
  sharedConstants,
  globalSystemConstants,
  galleryConstants,
} from "../../../constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function ViewGallery({
  files,
  setFiles,
  selectedFiles,
  isImageDeleted,
  setSelectedFiles,
  deleteFromGallery,
  progress,
  ...props
}) {
  const { t } = useTranslation("common");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAllFilesSelected, setIsAllFilesSelected] = useState(false);
  const [totalSelectedFiles, setTotalSelectedFiles] = useState("");
  const [activeTab, setActiveTab] = useState(galleryConstants.GALLERY_IMAGES);
  const [viewFiles, setViewFiles] = useState(files);
  const handleDeleteSelected = () => {
    props.handleDelete(selectedFiles);
    setTotalSelectedFiles("");
    setIsAllFilesSelected(false);
  };

  const handleDownloadSelected = async () => {
    if (selectedFiles.length > 1) {
      props.downloadSelectedFiles({
        ids: selectedFiles.map((file) => file.id),
        email: props.userName,
      });
      setTotalSelectedFiles("");
      setSelectedFiles([]);
    } else {
      let file = selectedFiles[0];
      handleSingleDownload(file);
    }
  };

  const handleSingleDownload = async (file) => {
    props.activateSpinner();
    try {
      const fileExtenssion = file?.url.split(".").pop();
      const fileName = file?.file?.name.replace(/\.$/, "");
      const fileUrl =
        fileName !== "undefined"
          ? window.config.downloadSingleFileUrl +
            fileName +
            "." +
            fileExtenssion
          : file?.url;
      let modifiedBlob;
      const randomNumber = Math.floor(Math.random() * 100) + 1;
      if (file.url.endsWith(".webp")) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        modifiedBlob = new Blob([blob], { type: "image/webp" });
      } else if (file.url.endsWith(".bmp")) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        modifiedBlob = new Blob([blob], { type: "image/bmp" });
      } else if (file.url.endsWith(".jpeg")) {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        modifiedBlob = new Blob([blob], { type: "image/jpeg" });
      } else {
        // For other file types, use the original Blob
        const response = await fetch(fileUrl);
        modifiedBlob = await response.blob();
      }
      const url = window.URL.createObjectURL(modifiedBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download =
        fileName !== "undefined"
          ? `${fileName}.${fileExtenssion}`
          : randomNumber;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);
      setTotalSelectedFiles("");
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
    props.diActivateSpinner();
  };

  const handleSelect = (file) => {
    if (file.isLocked && props.isShareAllowed) {
    } else {
      const index = selectedFiles.findIndex((f) => f === file);
      if (index !== -1) {
        setSelectedFiles((prevSelectedFiles) =>
          prevSelectedFiles.filter((f) => f !== file)
        );
      } else {
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
      }
    }
    setTotalSelectedFiles("");
    setIsAllFilesSelected(false);
  };

  const handleSelectAll = () => {
    if (!isAllFilesSelected && props.isShareAllowed) {
      let filteredFiles = viewFiles.filter((x) => x.isLocked === false);
      setIsAllFilesSelected(true);
      setTotalSelectedFiles(filteredFiles.length);
      setSelectedFiles(filteredFiles);
    } else if (isAllFilesSelected) {
      setIsAllFilesSelected(false);
      setTotalSelectedFiles("");
      setSelectedFiles([]);
    } else {
      setIsAllFilesSelected(true);
      setTotalSelectedFiles(viewFiles.length);
      setSelectedFiles(viewFiles);
    }
  };

  useEffect(() => {
    // Clean up object URLs when component unmounts
    return () => {
      viewFiles.forEach((file) => URL.revokeObjectURL(file.url));
    };
  }, [viewFiles]);

  const handleOpenModal = (index) => {
    setIsModalOpen(true);
    setCurrentImageIndex(index);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLockSelected = () => {
    props.handleLock(selectedFiles);
  };

  useEffect(() => {
    if (props.isAsyncUploadCall) {
      let counter = 0;

      files.forEach((file) => {
        if (!file.hasOwnProperty("id")) {
          counter++;
        }
      });

      props.setCounterTotalFilesToUpload(counter);
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (
      viewFiles.length > 0 &&
      activeTab === galleryConstants.GALLERY_IMAGES &&
      props.isTabsDisplay
    ) {
      setViewFiles(
        files?.filter((x) => x.galleryType === galleryConstants.GALLERY_IMAGES)
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleGalleryFiles(activeTab, true);
    // eslint-disable-next-line
  }, [files]);

  const handleGalleryFiles = (tab, isChangedFile = false) => {
    if (activeTab !== tab || isChangedFile) {
      setActiveTab(tab);
      setIsAllFilesSelected(false);
      setTotalSelectedFiles("");
      setSelectedFiles([]);
      if (tab === galleryConstants.GALLERY_REELS) {
        setViewFiles(
          files.filter(
            (x) =>
              x.galleryType === tab &&
              x.contentType === galleryConstants.CONTENT_TYPE_REELS
          )
        );
      } else {
        setViewFiles(
          files.filter(
            (x) =>
              x.galleryType === tab &&
              x.contentType === galleryConstants.CONTENT_TYPE_FILES
          )
        );
      }
    }
  };

  return (
    <>
      <div className="upload-gallery mt-2">
        <div
          className={`${
            props.isAuthenticated ? "file-buttons row mb-2 mx-0" : ""
          }`}
        >
          <div className="col-12 px-0 p-0">
            {progress > 0 && (
              <>
                {props.counterFilesUploaded <=
                  props.counterTotalFilesToUpload &&
                  props.isAsyncUploadCall && (
                    <>
                      <p className="p-0 m-0">
                        File:{" "}
                        {props.currentFileName.length > 50 ? (
                          <>
                            {props.currentFileName.slice(0, 42) +
                              "..." +
                              props.currentFileName.slice(-5)}
                          </>
                        ) : (
                          props.currentFileName
                        )}
                      </p>
                      <p className="p-0 m-0">
                        Size: {props.currentFileSize} Mbs
                      </p>
                      <p>
                        Uploading: {props.counterFilesUploaded} of{" "}
                        {props.counterTotalFilesToUpload}
                      </p>
                    </>
                  )}
                <ProgressBar
                  now={progress}
                  active
                  label={`${Number(progress).toFixed(0)}%`}
                />
              </>
            )}
          </div>
          {props.isTabsDisplay ? (
            <Box sx={{ width: "100%" }} className="p-0 mb-3 mt-2">
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  value={activeTab}
                  aria-label="Tabs"
                  className="member-my-events-tabs"
                >
                  <Tab
                    label="Images"
                    onClick={() => {
                      handleGalleryFiles(galleryConstants.GALLERY_IMAGES);
                    }}
                    aria-selected={
                      activeTab === galleryConstants.GALLERY_IMAGES
                        ? true
                        : false
                    }
                  />
                  <Tab
                    label="Videos"
                    onClick={() => {
                      handleGalleryFiles(galleryConstants.GALLERY_VIDEOS);
                    }}
                    aria-selected={
                      activeTab === galleryConstants.GALLERY_VIDEOS
                        ? true
                        : false
                    }
                  />

                  <Tab
                    label="Recap(z)"
                    onClick={() => {
                      handleGalleryFiles(galleryConstants.GALLERY_REELS);
                    }}
                    aria-selected={
                      activeTab === galleryConstants.GALLERY_REELS
                        ? true
                        : false
                    }
                  />
                </Tabs>
              </Box>
            </Box>
          ) : (
            ""
          )}
          {props.isAuthenticated || props.isSBAuthenticated ? (
            <div className="col-12 pl-0 pr-0 d-flex pb-2">
              <button
                className="gallery-btn-simple"
                disabled={viewFiles?.length === 0 || progress > 0}
                onClick={handleSelectAll}
              >
                {totalSelectedFiles !== ""
                  ? `${totalSelectedFiles} Selected`
                  : `Select All`}
              </button>
              {!props.hideDeleteIcon && (
                <button
                  className={
                    selectedFiles.length === 0
                      ? "border-0 pr-2 bg-none"
                      : "border-0 pr-2 bg-none cursor-pointer"
                  }
                  onClick={handleDeleteSelected}
                  disabled={selectedFiles.length === 0 || progress > 0}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              )}
              {props.isAllowDownload ? (
                <div
                  className={
                    selectedFiles.length === 0
                      ? "download-selected pr-2"
                      : "download-selected pr-2 cursor-pointer"
                  }
                  onClick={handleDownloadSelected}
                  disabled={selectedFiles.length === 0 || progress > 0}
                >
                  <i className="fas fa-download clr-blue"></i>
                </div>
              ) : (
                ""
              )}
              {props.isAllowLock ? (
                <div
                  className="cursor-point"
                  onClick={handleLockSelected}
                  disabled={progress > 0}
                >
                  <i class="fas fa-solid fa-lock"></i>
                </div>
              ) : (
                ""
              )}
              {/*props.isShareAllowed ? (
            <div className="shared-icon-con" disabled={progress > 0}>
              <i class="fa fa-share-alt" aria-hidden="true"></i>
            </div>
          ) : (
            ""
          )*/}
            </div>
          ) : (
            ""
          )}
        </div>
        {(props.isReelUpload ? files : viewFiles)?.filter(
          (file) => file.url !== sharedConstants.IS_GALLERY_UPLOAD_PREVIEW
        ).length ? (
          <div className="file-preview col-12 px-0 p-0">
            {(props.isReelUpload ? files : viewFiles)
              ?.filter(
                (file) => file.url !== sharedConstants.IS_GALLERY_UPLOAD_PREVIEW
              )
              .map((file, index) => (
                <div
                  key={index}
                  className={`file-item col-4 col-sm-3 px-0 ${
                    file.selected ? "selected" : ""
                  }`}
                >
                  {!isModalOpen &&
                    (props.isAuthenticated || props.isSBAuthenticated) && ( // Render checkboxes only when the modal is not open
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={
                            props.isShareAllowed && file.isLocked
                              ? false
                              : selectedFiles.includes(file)
                          }
                          onChange={() => handleSelect(file)}
                        />
                        {props.isShareAllowed && file.isLocked ? (
                          ""
                        ) : (
                          <span className="checkmark"></span>
                        )}
                      </label>
                    )}
                  {/* {!props.isAuthenticated &&
                !file.isLocked &&
                props.isAllowDownload ? (
                  <div
                    className={"lock-container"}
                    onClick={() => handleSingleDownload(file)}
                    // disabled={selectedFiles.length === 0 || progress > 0}
                  >
                    <i className="fas fa-download clr-blue"></i>
                  </div>
                ) : (
                  ""
                )} */}
                  {!isModalOpen && !file.file?.type.startsWith("image/") && (
                    <div
                      className="play-button-container"
                      onClick={(e) => {
                        if (props.isModalAllowed) {
                          e.preventDefault();
                          handleOpenModal(index);
                        }
                      }}
                    >
                      <i class="fas fa-solid fa-play fa-lg"></i>
                    </div>
                  )}

                  {!isModalOpen &&
                    (props.isAllowLock || props.isShareAllowed) &&
                    file.isLocked && (
                      <div className="lock-container">
                        <i className="fas fa-lock"></i>
                      </div>
                    )}

                  {!isModalOpen && !file.file?.type.startsWith("image/") && (
                    <div
                      className="play-button-container"
                      onClick={(e) => {
                        if (props.isModalAllowed) {
                          e.preventDefault();
                          handleOpenModal(index);
                        }
                      }}
                    >
                      <i class="fas fa-solid fa-play fa-lg"></i>
                    </div>
                  )}

                  <div className="aspect-ratio-container">
                    {file.file?.type.startsWith("image/") ? (
                      <>
                        <LazyLoad height={"fit-content"} threshold={0.95}>
                          <img
                            src={file?.id ? file.thumbnailUrl : file.url}
                            alt=""
                            className={`
                              ${
                                selectedFiles.includes(file)
                                  ? "selected-gallery-image file-content"
                                  : "file-content"
                              } obj-fit-cover`}
                            onClick={() => {
                              if (props.isModalAllowed) handleOpenModal(index);
                            }}
                          />
                        </LazyLoad>
                      </>
                    ) : (
                      <LazyLoad height={"fit-content"} threshold={0.95}>
                        <video
                          src={
                            file?.id
                              ? `${file.thumbnailUrl}#t=0.001`
                              : `${file.url}`
                          }
                          autoPlay={file?.id ? false : true}
                          muted={true}
                          preload="auto"
                          className={`
                            ${
                              selectedFiles.includes(file)
                                ? "selected-gallery-image col-12 px-0 h-100 file-content"
                                : "col-12 px-0 h-100 file-content"
                            } obj-fit-cover`}
                          onClick={(e) => {
                            if (props.isModalAllowed) {
                              e.preventDefault();
                              handleOpenModal(index);
                            }
                          }}
                        ></video>
                      </LazyLoad>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center pt-3">
            <p>{t("no_files_uploaded")}</p>
          </div>
        )}

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Image Modal"
          className="image-modal modal-border-color"
        >
          <div className="modal-content">
            {viewFiles[currentImageIndex]?.file &&
              (viewFiles[currentImageIndex].file?.type.startsWith("image/") ? (
                <>
                  {" "}
                  {!viewFiles[currentImageIndex].isLocked &&
                  !props.isSBAuthenticated ? (
                    <div
                      className={"image-download-icon"}
                      onClick={() =>
                        handleSingleDownload(viewFiles[currentImageIndex])
                      }
                    >
                      <i className="fas fa-download text-light"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <img
                    src={
                      viewFiles[currentImageIndex]?.id
                        ? viewFiles[currentImageIndex].url
                        : URL.createObjectURL(viewFiles[currentImageIndex].file)
                    }
                    alt=""
                    className="modal-image"
                  />
                </>
              ) : (
                <>
                  {" "}
                  {!viewFiles[currentImageIndex].isLocked &&
                  !props.isSBAuthenticated ? (
                    <div
                      className={"video-download-icon"}
                      onClick={() =>
                        handleSingleDownload(viewFiles[currentImageIndex])
                      }
                    >
                      <i className="fas fa-download text-light"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <video
                    src={
                      viewFiles[currentImageIndex]?.id
                        ? viewFiles[currentImageIndex].url
                        : URL.createObjectURL(viewFiles[currentImageIndex].file)
                    }
                    className="modal-image"
                    controls
                  ></video>
                </>
              ))}

            <button onClick={handleCloseModal} className="modal-close">
              <img
                src="/images/simpleCrossWhite.svg"
                alt="crossIcon"
                className="modal-close-img"
              />
            </button>
            <button
              className="prev-btn left-btn"
              disabled={currentImageIndex === 0}
              onClick={() => setCurrentImageIndex((prevIndex) => prevIndex - 1)}
            >
              <img
                src="/images/previousArrowButtonWhite.svg"
                alt="previousArrowButton"
                className="left-icon-image"
              />
            </button>
            <button
              className="next-btn right-btn"
              onClick={() => setCurrentImageIndex((prevIndex) => prevIndex + 1)}
              disabled={currentImageIndex === viewFiles.length - 1}
            >
              <img
                src="/images/nextArrowButtonWhite.svg"
                alt="nextArrowButton"
                className="right-icon-image"
              />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

// Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userName: state.authentication.userName,
  isAuthenticated: state.authentication.isAuthenticated,
  isSBAuthenticated: state.shutterbAuthentication.isShutterbAuthenticated,
});

// Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  downloadSelectedFiles: (eventIdsArr) =>
    dispatch(customerDashboardActions.downloadSelectedFiles(eventIdsArr)),
  activateSpinner: () =>
    dispatch({
      type: globalSystemConstants.DOWNLOAD_SPINNER_ACTIVATE,
      data: null,
    }),
  diActivateSpinner: () =>
    dispatch({
      type: globalSystemConstants.DOWNLOAD_SPINNER_DIACTIVATE,
      data: null,
    }),
});

// mapping action and store the function via props
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(memo(ViewGallery)));
